// JS File
import React from "react";
import "../NotaryPublic.css"; // Create a CSS file for styling specific to this page
import "../../App.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';

const NotaryPublicServices = () => {
  return (
    <>
      <div className='service'>
        <div className='Grid'>
          <div className='left'>
            <img style={{ width: '100%' , height: '100%' , borderRadius: '10px', maxHeight: '800px', maxWidth: '700px' }} src='/images/notary-document.jpg' alt='Notary Services' />
          </div>
          <div className='right'>
            <div className='inner-content'>
              <h1>Reliable Notary Public Services in Mountain View</h1>
              <p>At Live Scan Solutions, our Notary Public professionals ensure your documents are notarized accurately and efficiently, giving you peace of mind.</p>
              <h4> Services We Offer</h4>

              <a href='/' target='_blank'><span><DoneIcon sx={{ color: 'green' }} />&nbsp;Live Scan</span></a>
              <a href='/apostilles' target='_blank'><span><DoneIcon sx={{ color: 'green' }} />&nbsp;Apostille Services</span></a>
              <a href='/passport-photos' target='_blank'><span><DoneIcon sx={{ color: 'green' }} />&nbsp;Passport Photo</span></a>
              <a href='/mailbox-rental' target='_blank'><span><DoneIcon sx={{ color: 'green' }} />&nbsp;Mailbox Rental</span></a>
              <a href='/pack-ship' target='_blank'><span><DoneIcon sx={{ color: 'green' }} />&nbsp;Pack & Ship</span></a>
            </div>
          </div>
        </div>
      </div>

      <div className="notary-container">

        <section className="steps">
          <h1>Our Step-by-Step Notary Process</h1>
          <div className="step">
            <img src="/images/Walkin.jpg" alt="Consultation" />
            <h3>Step 1: Consultation</h3>
            <p>
              Contact us to discuss your document notarization requirements and confirm the necessary identification.
            </p>
          </div>
          <div className="step">
            <h3>Step 2: Document Preparation</h3>
            <p>
              Ensure your documents are ready for notarization. Our team will guide you through the process.
            </p>
            <img src="/images/Document.jpg" alt="Document Preparation" />
          </div>
          <div className="step">
            <img src="/images/step3-notary.png" alt="Verification" />
            <h3>Step 3: Identity Verification</h3>
            <p>
              Present valid identification for verification. We ensure compliance with legal standards.
            </p>
          </div>
          <div className="step">
            <h3>Step 4: Notarization</h3>
            <p>
              Our licensed Notary Public will witness the signing of your document and provide the official seal.
            </p>
            <img src="/images/ink-scan.jpg" alt="Notarization" />
          </div>
        </section>

        <section className="benefits">
          <h1>Why You Need a Notary Public</h1>
          <ul>
            <li><strong>Contracts:</strong> Legal agreements between two or more parties.</li>
            <li><strong>Real Estate Documents:</strong> Deeds, mortgages, and other property-related papers.</li>
            <li><strong>Affidavits:</strong> Written statements confirmed by oath or affirmation, for use as evidence in court.</li>
            <li><strong>Power of Attorney:</strong> Legal authorization for one person to act on another's behalf in legal matters.</li>
            <li><strong>Wills and Trusts:</strong> Legal documents related to the distribution of a person's estate after death.</li>
            <li><strong>Business Documents:</strong> Articles of incorporation, business licenses, and partnership agreements.</li>
          </ul>
        </section>

        <section className="benefits">
          <h1>Types of Notarizations We Offer</h1>
          <ul>
            <li><strong>Acknowledgments:</strong> Used to confirm the identity of the signer and their willingness to sign a document.</li>
            <li><strong>Jurats:</strong> Requires the signer to swear or affirm that the contents of a document are true.</li>
            <li><strong>Oaths and Affirmations:</strong> Legally binding promises made in writing or verbally before a Notary.</li>
            <li><strong>Certified Copies:</strong> Notary-certified copies of original documents, ensuring that the copy is an accurate reproduction.</li>
            <li><strong>Signature Witnessing:</strong> The Notary Public witnesses the signing of a document and verifies the identity of the signer.</li>
          </ul>
        </section>

        <section className="benefits">
          <h1>Why Choose Our Notary Public Services?</h1>
          <ul>
            <li>Convenience: Walk-in and on-site services for your needs.</li>
            <li>Expertise: Licensed Notaries with extensive experience.</li>
            <li>Security: Confidential handling of your sensitive documents.</li>
            <li>Accessibility: Serving Mountain View and the Bay Area.</li>
            <li>Accuracy: Ensuring your documents meet legal requirements.</li>
          </ul>
        </section>

        <section className="faq">
          <h1>Frequently Asked Questions</h1>
          <div className="accordion">
            <Accordion className='accordion_item'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>What documents require notarization?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Common documents include real estate deeds, affidavits, power of attorney, and contracts.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className='accordion_item'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Do I need an appointment?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Walk-ins are welcome, but appointments are recommended for faster service.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </section>

        <footer className="footer">
          <h2>Contact Us for Notary Services</h2>
          <p>Reach out to Live Scan Solutions for reliable and efficient Notary Public services.</p>
        </footer>
      </div>
    </>
  );
};

export default NotaryPublicServices;